<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="../../assets/img/wang/back.png" @click="back" alt="" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="info-card" v-loading="ifLoading">
        <div class="title">
          <div class="text" v-html="myInfo.accountName ? myInfo.accountName : ''"></div>
          <div class="button" @click="deleteFnMy(myInfo)">删除平台</div>
        </div>
        <div class="operation">
          <div class="operation-left" v-if="myInfo.messageTypeNameList">
            <!-- <span v-if="myInfo.bodyTypeName" class="spn4">
                {{ myInfo.bodyTypeName }}
              </span> -->
            <div class="status" v-if="myInfo.messageTypeNameList.length == 0">
              正常
            </div>
            <el-popover v-else placement="right-start" popper-class="monitor-yt-popover" effect="dark" width="400" trigger="hover">
              <div class="popover">
                <div class="popover-type m-l-16 m-t-16" v-for="(value, inx) in myInfo.messageTypeNameList" :key="inx">
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">{{ value }}</div>
                    <div class="texthidden">{{ value }}</div>
                  </el-tooltip>
                </div>
              </div>
              <div slot="reference">
                <div class="type" v-if="myInfo.messageTypeNameList.length > 0">
                  {{ myInfo.messageTypeNameList[0] }}
                </div>
              </div>
            </el-popover>

            <span>标签</span>
            <el-select class="select" v-model="myInfo.labelId" @change="ChangeFn" placeholder="请选择">
              <el-option v-for="item in infoLabelList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="operation-right">
            <div class="bad" @click="toDetail(4, myInfo)">
              <img src="../../assets/img/wang/toxic.png" />
              <span>有害信息</span>
            </div>
            <div class="contet" @click="toDetail(2, myInfo)">
              <img src="../../assets/img/wang/content-info.png" />
              <span>内容信息</span>
            </div>
            <div class="management" @click="toDetail(3, myInfo)">
              <img src="../../assets/img/wang/chuzhi.png" />
              <span>处置信息</span>
            </div>
          </div>
        </div>
        <div class="info-detail">
          <div class="info-detail-left">
            <div class="platform">
              <div class="company">平台类型：{{ myInfo.platformTypeName }}</div>
              <div class="company" v-if="myInfo.platformType == 1">
                主办单位：{{ myInfo.companyName ? myInfo.companyName : "无" }}
              </div>
              <!-- <div class="company" v-else>
                作者：{{ myInfo.accountName ? myInfo.accountName : "无" }}
              </div> -->
              <div class="company">
                发文数: {{ myInfo.contentCount }}
              </div>
              <div class="company">
                所属区域：{{ myInfo.areaName ? myInfo.areaName : "无" }}
              </div>
              <div class="company" v-if="myInfo.platformType == 1">
                ICP备案：{{ myInfo.icpNumber|| "无" }}
              </div>
              <div class="company" v-if="myInfo.platformType !== 1">
                粉丝数：{{ myInfo.fans ? myInfo.fans : "无" }}
              </div>
              <div class="company">
                主体类型：{{ myInfo.bodyTypeName ? myInfo.bodyTypeName : "无" }}
              </div>
              <!-- <div class="company">运行状态：{{myInfo.state==1?'运行中':myInfo.state==0?'已暂停':'已关闭'||'无'}}</div> -->
              <div class="company" v-if="myInfo.platformType !== 1">
                关注数：{{ myInfo.attention ? myInfo.attention : "无" }}
              </div>

              <div class="company" v-if="myInfo.platformType == 1">
                网站级别：{{ myInfo.accountLevelName  || '无'}}
              </div>
              <div class="company" v-else>
                账号级别：{{ myInfo.accountLevelName  || '无'}}
              </div>
              <div class="company">运营内容: {{ myInfo.managementContentName|| '无' }}</div>
              <div class="content-info-middle">
                <!-- 网站域名：{{ myInfo.homeUrl ? myInfo.homeUrl : "无" }} -->
                <span style="float:left;min-width:76px">{{myInfo.platformType == 1 ? '网站域名' :"账号域名"}}: &nbsp; &nbsp;</span>
                <a class="aColor cursor texthidden" style="display:block;float:left" @click="$main.lookYuanWen(myInfo.homeUrl)" target="_blank"
                   rel="noopener noreferrer">{{ myInfo.homeUrl }}</a>
                <img class="cursor" style="margin-left: 10px;float:left" @click="$main.copyFun(myInfo.homeUrl, myInfo.platformType == 1 ? '网站域名' :'账号域名')"
                     src="@/assets/img/wang/copy-all.png" />
              </div>
            </div>
          </div>
          <!-- headSmallUrl -->
          <el-image v-if="myInfo.headImage" class="img" :src="myInfo.headImage" :preview-src-list="[myInfo.headImage]">
            <div slot="error" class="image-slot">
              <img src="../../assets/img/zhanwei2.png" alt="" srcset="" />
            </div>
          </el-image>
          <!-- <img v-else src="../../assets/img/zhanwei.png" alt="" srcset=""> -->
        </div>
      </div>
      <div class="info-type" v-loading="ifLoading">
        <div class="cut">
          <div class="cut-left">
            <div class="edit" @click="editFn">编辑</div>
            <div v-for="(item, index) in list" @click="cut(item.id)" :key="index" :style="
                item.id == idx
                  ? 'border-bottom:2px solid #ED731F;color:#ED731F'
                  : ''
              " class="tab">
              {{ item.name }}
            </div>
          </div>
          <div class="cut-right">

          </div>
        </div>
        <div class="p-20">
          <!--网站账号信息 -->
          <div class="webDetailsInInput" v-if="myInfo.platformType == 1">
            <div class="webDetailsInInputP">
              <p>
                <img src="../../assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>网站信息</span>
              </p>
            </div>
            <div class="account-information">
              <div class="website-name" v-if="myInfo.accountName">
                网站名称：{{ myInfo.accountName || "无" }}
              </div>
              <div class="website-name" v-if="myInfo.domainName">
                网站地址 :&nbsp; &nbsp; <a class="aColor cursor" @click="$main.lookYuanWen(myInfo.domainName)" target="_blank"
                   rel="noopener noreferrer">{{ myInfo.domainName }}</a>
                <img class="cursor" style="margin-left:10px" @click="$main.copyFun(myInfo.domainName, '网站复制')" src="@/assets/img/wang/copy-all.png" />

              </div>
              <!-- <div class="website-name" >
            网站状态: {{ myInfo.messageTypeNameList.length > 0? myInfo.messageTypeNameList[0]: "正常" }}
          </div> -->
              <div class="website-name">
                境内外接入: {{ myInfo.insideOrOutsideName || "境内" }}
              </div>
              <div class="website-name">接入国家：{{myInfo.accessCountryName || '中国'}}</div>
              <div class="website-name">
                <!-- 接入省份：{{ myInfo.accessProvince || "无" }} -->
                接入省份 ：{{myInfo.accessProvince ? myInfo.accessProvince:'无'}}
              </div>

              <div class="website-name">
                网站编码类型：{{ myInfo.siteEncoding || "无" }}
              </div>
              <!-- lastAutoUpdateStatusTime -->
              <div class="website-name">
                最后更新时间：{{ myInfo.lastAutoUpdateStateTime }}
              </div>

              <!-- <div class="website-name">
            备注信息：{{ myInfo.remark || "无" }}
          </div> -->
              <div class="website-name textHidden1" v-if="myInfo.messageTypeNameList.length > 0">
                网站状态：{{ myInfo.messageTypeNameList.join(',')|| "无" }}
              </div>
              <div class="website-name">
                服务器ip地址：{{ myInfo.serverIp || '无'}}
              </div>
              <div class="website-name">
                服务器机房信息：{{ myInfo.serverRoom  || '无'}}
              </div>

              <div class="website-name" v-if="myInfo.bodyTypeName">
                网站分类：{{ myInfo.bodyTypeName || "无" }}
              </div>
              <div class="website-name">
                语言种类：{{ myInfo.siteLanguage || '无'}}
              </div>
            </div>
          </div>
          <!--微博账号信息 -->
          <div class="webDetailsInInput" v-if="myInfo.platformType == 2">
            <div class="webDetailsInInputP">
              <p>
                <img src="../../assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>微博信息</span>
              </p>
            </div>
            <div class="account-information">
              <div class="website-name">账号ID：{{myInfo.accountId ||  '无'}}</div>
              <div class="website-name" v-if="myInfo.homeUrl">主页地址：
                <!-- {{myInfo.homeUrl || '无'}} -->
                <a class="aColor cursor textHidden1" style="max-width:80%" @click="$main.lookYuanWen(myInfo.homeUrl)" target="_blank"
                   rel="noopener noreferrer">{{ myInfo.homeUrl || '无'}}</a>
                <img class="cursor" style="margin-left:10px" @click="$main.copyFun(myInfo.homeUrl, '网站复制')" src="@/assets/img/wang/copy-all.png" />
              </div>
              <div class="website-name">用户名称：{{myInfo.accountName || '无'}}</div>
              <div class="website-name">电话号码：{{myInfo.bindPhone ||'无'}}</div>
              <!-- <div class="website-name">所属省份：{{myInfo.areaName.split('-')[0] || '无'}}</div>
            <div class="website-name">所属城市：{{myInfo.areaName.split('-')[1] || '无'}}</div> 
            <div class="website-name">所属区县：{{myInfo.areaName.split('-')[2] ||'无'}}</div> -->
              <div class="website-name">关键词：</div>
              <div class="website-name">
                最后更新时间：{{ myInfo.lastUpdateTime || "无" }}
              </div>
              <div class="website-name">
                注册时间:{{ myInfo.registeTime || "无" }}
              </div>
              <div class="website-name">所属地区：{{ myInfo.areaName || '无' }}</div>
              <div class="website-name">
                认证情况：{{ myInfo.isAuthenticateName || "无" }}
              </div>
              <div class="website-name">
                粉丝数量：{{ myInfo.fans || "无" }}
              </div>
              <div class="website-name">
                关注数量：{{ myInfo.attention || "无" }}
              </div>

              <div class="website-name">
                标签信息：{{ myInfo.label || "无" }}
              </div>
              <div class="website-name">
                简介：{{ myInfo.briefIntroduction || "无" }}
              </div>

              <div class="website-name">
                发文数量：{{ myInfo.contentCount }}
              </div>
              <div class="website-name" v-if="myInfo.nickName">
                微博昵称：{{ myInfo.nickName || "无" }}
              </div>
              <!-- <div class="website-name" v-if="myInfo.bindPhone">
            手机号：{{ myInfo.bindPhone || "无" }}
          </div> -->
            </div>
          </div>
          <!--微信账号信息 -->
          <div class="webDetailsInInput" v-if="myInfo.platformType == 3">
            <div class="webDetailsInInputP">
              <p>
                <img src="../../assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>微信信息</span>
              </p>
            </div>
            <div class="account-information">
              <div class="website-name">账号ID：{{myInfo.accountId ||  '无'}}</div>
              <div class="website-name" v-if="myInfo.homeUrl">主页地址：
                <a class="aColor cursor textHidden1" style="max-width:80%" @click="$main.lookYuanWen(myInfo.homeUrl)" target="_blank"
                   rel="noopener noreferrer">{{ myInfo.homeUrl }}</a>
                <img class="cursor" style="margin-left:10px" @click="$main.copyFun(myInfo.homeUrl, '网站复制')" src="@/assets/img/wang/copy-all.png" />
              </div>
              <div class="website-name">用户名称：{{myInfo.accountName || '无'}}</div>
              <div class="website-name">注册时间：{{ myInfo.createTime || "无" }}</div>

              <div class="website-name">最后更新时间：{{myInfo.lastUpdateTime || '无'}}</div>
              <!-- <div class="website-name">所属省份：{{myInfo.areaName.split('-')[0] || '无'}}</div>
            <div class="website-name">所属城市：{{myInfo.areaName.split('-')[1] || '无'}}</div> 
            <div class="website-name">所属区县：{{myInfo.areaName.split('-')[2] ||'无'}}</div> -->
              <div class="website-name">
                微信账号： {{myInfo.weixinAccount|| "无" }}
              </div>
              <div class="website-name">
                公众号名称：{{myInfo.accountName|| "无" }}
              </div>
              <div class="website-name">
                发文数量：{{ myInfo.contentCount  }}
              </div>
              <div class="website-name">账号主体类型：{{getBodyTypeName(myInfo.accountBodyType)  || '无'}}</div>
              <!-- <div class="website-name" >
            账号主体名称：{{ myInfo.accountBodyName || "无" }}
          </div> -->

              <div class="website-name">
                处置状态：{{ myInfo.disposeStateName || "无" }}
              </div>

              <div class="website-name">
                认证时间：{{ myInfo.authenticateTime || "无" }}
              </div>

              <div class="website-name" v-if="myInfo.messageTypeNameList.length > 0">
                账号状态：{{ myInfo.messageTypeNameList.join(',') || "无" }}
              </div>
              <div class="website-name">
                所属地区：{{ myInfo.areaName || "无" }}
              </div>
              <div class="website-name">
                手机号：{{ myInfo.customerServicePhone || "无" }}
              </div>
              <div class="website-name">简介：{{myInfo.introduction || '无'}}</div>
            </div>
          </div>

          <!--公共账号信息 -->
          <div class="webDetailsInInput" v-if="myInfo.platformType > 3">
            <div class="webDetailsInInputP">
              <p>
                <img src="../../assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>账号信息</span>
              </p>
            </div>
            <div class="account-information">
              <div class="website-name">账号ID：{{myInfo.accountId ||  '无'}}</div>
              <!-- <div class="website-name">所属省份：{{myInfo.areaName.split('-')[0] || '无'}}</div>
            <div class="website-name">所属城市：{{myInfo.areaName.split('-')[1] || '无'}}</div> 
            <div class="website-name">所属区县：{{myInfo.areaName.split('-')[2] ||'无'}}</div> -->
              <div class="website-name">所属地区：{{myInfo.areaName||'无'}}</div>
              <div class="website-name">
                粉丝数量： {{ myInfo.fans || "无" }}
              </div>
              <div class="website-name">作者名称： {{ myInfo.accountName || '无' }}</div>
              <div class="website-name">
                关注数量： {{ myInfo.attention  }}
              </div>
              <div class="website-name">
                简介： {{ myInfo.introduction || "无" }}
              </div>
              <div class="website-name">
                发文数量： {{ myInfo.contentCount  }}
              </div>
            </div>
          </div>
          <!-- 备案信息 -->
          <div class="webDetailsInInput" v-if=" myInfo.platformType == 1">
            <div class="webDetailsInInputP">
              <p>
                <img src="../../assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>备案信息</span>
              </p>
            </div>
            <div class="account-information">
              <div class="website-name">
                网站名称：{{ myInfo.accountName || "无" }}
              </div>

              <div class="website-name">
                网站icp备案号：{{myInfo.icpNumber ||  myInfo.orgIcpNumber || "无" }}
              </div>
              <div class="website-name">
                网站域名：{{ myInfo.domainName || "无" }}
              </div>
              <div class="website-name">
                主办单位名称：{{ myInfo.companyName || "无" }}
              </div>
              <div class="website-name">
                网站首页地址：
                <a class="aColor cursor" @click="$main.lookYuanWen(myInfo.homeUrl)" target="_blank" rel="noopener noreferrer">{{ myInfo.homeUrl }}</a>
                <img class="cursor" style="margin-left:10px" @click="$main.copyFun(myInfo.homeUrl, '网站复制')" src="@/assets/img/wang/copy-all.png" />
              </div>

              <div class="website-name">
                主办单位性质：{{ myInfo.organizerTypeName || "无" }}
              </div>

              <div class="website-name">
                主办单位地址：{{ myInfo.companyAddress || "无" }}
              </div>
              <div class="website-name">
                网站负责人电话：{{ myInfo.sitePersonphone || "无" }}
              </div>
              <div class="website-name">
                所属地区：{{ myInfo.areaName || "无" }}
              </div>
              <!-- <div class="website-name">所属省份：{{ myInfo.provance }}</div> -->
              <div class="website-name">
                审核时间：{{ myInfo.passTime || "无" }}
              </div>
              <!-- <div class="website-name" >
            是否限制接入：{{ myInfo.isLimitAccess || "无" }}
          </div>
          <div class="website-name" >
            前置审批：{{ myInfo.preApprovalName || "无" }}
          </div> -->

            </div>
          </div>
          <!-- 工商信息 -->
          <div class="webDetailsInInput" v-if="businessInfoList &&  Object.keys(businessInfoList).length > 0">
            <div class="webDetailsInInputP">
              <p>
                <img src="../../assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>工商信息</span>
              </p>
            </div>
            <div class="account-information">
              <!-- <Bitmap v-if="businessInfoList.length == 0"></Bitmap> -->
              <div class="website-name">
                经营状态：{{ (businessInfoList.operatingStatus=='0'?'正常经营':'暂停经营') || "无" }}
              </div>
              <div class="website-name">
                企业名称：{{ businessInfoList.companyName || "无" }}
              </div>
              <div class="website-name">
                法人代表人：{{ businessInfoList.legalPerson }}
              </div>
              <div class="website-name">
                注册资金：{{ businessInfoList.registeredFunds || "无" }}
              </div>
              <div class="website-name" v-if="businessInfoList.createDate">
                成立时间：{{ businessInfoList.createDate || "无" }}
              </div>
              <!-- <div class="website-name" >
            所属地区：{{ businessInfoList.areaName || "无" }}
          </div> -->
              <div class="website-name">
                电话：{{ businessInfoList.phone || "无" }}
              </div>
              <div class="website-name">
                更多电话：{{ businessInfoList.morePhone || "无" }}
              </div>
              <div class="website-name">
                邮箱：{{ businessInfoList.email || "无" }}
              </div>
              <div class="website-name">
                统一社会信用代码：{{ businessInfoList.socialCreditCode || "无" }}
              </div>
              <div class="website-name">
                纳税人识别号：{{ businessInfoList.taxIdentyNumber || "无" }}
              </div>
              <!-- <div class="website-name" v-if="businessInfoList.registeredNumber">
            注册号：{{ businessInfoList.registeredNumber || "无" }}
          </div> -->
              <div class="website-name">
                组织机构代码： {{ businessInfoList.organizationCode || "无" }}
              </div>
              <div class="website-name">
                公司类型：{{ businessInfoList.companyType || "无" }}
              </div>
              <div class="website-name">
                所属行业：{{ businessInfoList.belongIndustry || "无" }}
              </div>

              <div class="website-name">
                网址：{{ businessInfoList.url || "无" }}
              </div>
              <div class="website-name">
                企业地址：{{ businessInfoList.companyAddress || "无" }}
              </div>

              <div class="website-name">所属城市：{{ businessInfoList.cityName || '无' }}</div>
              <div class="website-name ">
                经营范围：<span class="texthidden" style="width:85%">{{ businessInfoList.businessScope || "无" }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- 相似账号 -->
      <div class="be-similar" v-loading="ifLoading">
        <div class="be-similar-account">
          <span>相似账号</span><span>（{{ totalCount }}条）</span>
          <div class="be-similar-color"></div>
        </div>
        <Bitmap v-if="!myLikeList.length || myLikeList.length == 0" />
        <template v-if="myLikeList.length > 0">
          <div class="data-box" v-for="(item, index) in myLikeList" :key="index">
            <div class="data-box-left">
              <img :src="item.headImage" alt="" />
              <div class="platform-type">{{ item.platformTypeName }}</div>
            </div>
            <div class="data-box-right">
              <div class="name-box">
                <div class="name" @click="toDetail(1, item)">
                  {{ item.accountName }}
                </div>
              </div>
              <div class="data-info">
                <div>平台状态：</div>
                <template v-if="item.messageTypeNameList.length > 0">
                  <span class="colRed">{{ item.messageTypeNameList[0] + "   " }}
                  </span>
                  <el-tooltip class="item" effect="dark" placement="top">
                    <div slot="content" v-html="item.messageTypeNameDesc"></div>
                    <span>(更多)</span>
                  </el-tooltip>
                </template>
                <div class="status" v-else>正常</div>
                <!-- <div class="status">{{ item.messageTypeList }}</div> -->
                <div class="region">
                  所属区域：{{ $main.ifText(item.areaName) }}
                </div>
                <div class="subject-type">
                  主体类型：{{ $main.ifText(item.bodyTypeName) }}
                </div>
                <div class="company" v-if="item.platformType == 1">
                  主办单位：{{ $main.ifText(item.companyName) }}
                </div>
                <div class="region" v-if="item.platformType == 1">
                  ICP备案：{{ $main.ifText(item.icpNumber) }}
                </div>
                <template v-else>
                  <div class="fnas" v-if="$main.listConfigPlatformShow(item.platformType,2)">粉丝数：{{ $main.ifText(item.fans) }}</div>
                  <div class="fnas" v-if="$main.listConfigPlatformShow(item.platformType,3)">关注数：{{ $main.ifText(item.attention) }}</div>
                </template>
                <div class="fnas" v-if="$main.listConfigPlatformShow(item.platformType,1)">
                  发文数：{{ item.contentCount }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 删除弹出框 -->
    <DeleteDialog ref="openDialog" @deleteFn="deleteaccount"></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "../../components/deleteDialog.vue";
import Bitmap from "../../components/bitmap.vue"; // 位图
import Breadcrumb from "@/components/breadcrumb.vue";
// import { list } from "../../api/monitor";
import {
  detail,
  queryPage,
  getList,
  account,
  businessInfo,
  deleteaccount,
} from "../../api/platformaccount/index";
export default {
  components: { Breadcrumb, Bitmap, DeleteDialog },
  data() {
    return {
      ifLoading: true,
      routerList: ["平台台账", "详情"],
      tagsOptions: [{ label: "标签", value: "0" }],
      list: [
        // { name: "账号信息", id: 0 },
        // { name: "备案信息", id: 1 },
        // { name: "工商信息", id: 2 },
      ],
      idx: 0,
      myInfo: {}, // 详情
      myLikeList: [], // 相似账号
      infoLabelList: [], // 标签
      totalCount: 0,
      listInfo: {}, // 列表上带过来的参数
      businessInfoList: {}, // 工商信息 先默认为数组，为了用length判断 真实数据是对象 
      body_type: JSON.parse(window.localStorage.getItem("body_type")) || []
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    console.log(params, '信息');
    if (params) {
      this.listInfo = params;
    }
    this.getDetail(); // 详情接口
    this.getList(); // 标签列表
  },
  methods: {
    editFn() {
      // const {platformType} = this.myInfo
      this.$router.push({
        path: '/newAccount',
        query: {
          isEdit: true,
          ...this.myInfo,
          businessInfoList: JSON.stringify(this.businessInfoList)
        }
      })
    },
    getBodyTypeName(type) {
      let name
      this.body_type.forEach(v => {
        if (v.value == type) {
          name = v.label
          return
        }
      })
      return name
    },
    deleteFnMy(item) {
      item.message = "这个平台";
      this.$refs.openDialog.openDialog(item);
    },
    // 执行删除请求
    async deleteaccount({ item: { accountId, platformType } }) {
      const Res = await deleteaccount({ accountId, platformType });
      if (Res.data.code == 200) {
        this.$message({
          message: `操作成功`,
          type: "success",
        });
        await this.$main.mySetTimeOut(500);
        this.$router.push('/platformaccount')
        window.close()
      } else {
        this.$message.error("操作失败!");
      }
    },
    async getList() {
      const Res = await getList({});
      // if(this.$main.ifRes(Res)) return
      const {
        data: { list },
      } = this.$main.getResData(Res);
      this.infoLabelList = list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    },
    // 列表标签 方法
    async ChangeFn(e) {
      const { accountId, platformType, labelIds } = this.myInfo;
      const Res = await account({
        labelIds: labelIds ? labelIds + "," + e : e,
        accountId,
        platformType,
      });
      // if (this.$main.ifRes(Res)) return; 
      if (Res.data.code == 200) {
        this.$message.success("操作成功！");
        return;
      }
    },
    // 详情接口
    async getDetail() {
      const { accountId, platformType } = this.listInfo;
      try {
        const Res = await detail({ accountId, platformType });
        const { data } = this.$main.getResData(Res);
        this.myInfo = data;
        // this.list =
        //   data.platformType == 1
        //     ? [
        //         { name: data.platformTypeName.trim() + "信息", id: 0 },
        //         { name: "备案信息", id: 1 },
        //         { name: "工商信息", id: 2 },
        //       ]
        //     : [
        //         { name: data.platformTypeName.trim() + "信息", id: 0 },
        //         { name: "工商信息", id: 2 },
        //       ];
        this.ifLoading = false;
        this.getquerySimilarPage(data); // 相似账号接口
        // 如果没有社会统一代码  就表示 他没写工商信息  那还调个鸡儿
        if (!data.socialCreditCode) return
        this.businessInfo(data.socialCreditCode); // 调工商信息
      } catch (error) {
        this.ifLoading = false;
      }
    },
    // 工商信息
    async businessInfo(socialCreditCode) {
      const Res = await businessInfo(socialCreditCode);
      if (Res.data.code == 200) {

        this.businessInfoList = Res.data.data || [];
        console.log(this.businessInfoList.length > 1)
      }
    },
    // 相似账号接口
    async getquerySimilarPage({ accountName, companyName, id }) {
      const Res = await queryPage({
        accountName,
        companyName,
        id,
        similar: true,
        pageNo: 1,
        pageSize: 50
      });
      const {
        data: { list, totalCount },
      } = this.$main.getResData(Res);
      list.forEach((v) => {
        if (v.messageTypeNameList.length > 0) {
          v.messageTypeNameDesc = v.messageTypeNameList
            .join(" ")
            .replace(new RegExp(" ", "g"), "<br/>");
        }
      });
      this.myLikeList = list;
      this.totalCount = totalCount > 50 ? 50 : totalCount; // 最大只显示 50
    },
    back() {
      this.$router.push("/platformaccount");
    },
    cut(index) {
      this.idx = index;
    },
    //详情
    toDetail(inx, item) {
      // 1 平台台账详情 accountDetail
      // 2 发布内容列表 publishingcontent
      // 3 监测方案列表 monitor
      // 4 有害信息列表 harmful
      const routeObj = {
        1: { path: "/accountDetail", query: item },
        2: { path: "/publishingcontent", query: { ...item, pageType: 1 } },
        3: { path: "/questionDetail", query: { item } },
        4: { path: "/harmful", query: { ...item, pageType: 1 } },
      }[inx];
      const { path, query } = routeObj;
      this.$main.routeResolve(path, query);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-info-middle {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  width: 33%;
  // height: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  // line-height: 20px;
}
.listImg-detils {
  margin-right: 8px;
}
.webDetailsInInput {
  border: 1px solid #e6e8f1;
  margin-bottom: 16px;
  border-radius: 4px;
  .el-row {
    padding: 15px 24px 15px 24px;
  }
  .webDetailsInInputP {
    height: 54px;
    background-color: #f5f5fc;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    p {
      display: flex;
      align-items: center;
    }
    span {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.spn4 {
  max-width: 200px;
  background: rgba(255, 128, 0, 0.2);
  border-radius: 2px;
  font-size: 14px;
  color: #ff8000;
  padding: 3px 5px;
  margin-right: 5px;
}
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .info-card {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      background: #ffffff;
      padding: 32px 40px;
      margin-top: 16px;
      .title {
        display: flex;
        height: 32px;
        width: 100%;
        margin-bottom: 24px;
        .text {
          height: 32px;
          width: calc(100% - 88px);
          line-height: 32px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 88px;
          height: 32px;
          background: #fff2f3;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #e60b1e;
          cursor: pointer;
        }
      }
      .operation {
        display: flex;
        height: 32px;
        display: flex;
        justify-content: space-between;
        .operation-left {
          display: flex;
          align-items: center;
          width: 40%;
          height: 32px;
          .status {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 32px;
            padding: 0 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background: #02bc7c;
            margin-right: 24px;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-right: 8px;
          }
          .select {
            width: 110px;
            height: 32px;
            margin: 0px 24px 0 8px;
            ::v-deep .el-input__inner {
              width: 110px;
              height: 32px;
              border-radius: 4px;
            }
            ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            ::v-deep .el-input__icon.el-icon-arrow-down {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            ::v-deep .el-input__icon.el-icon-circle-close {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            ::v-deep .el-cascader {
              height: 32px !important;
              margin-top: 0px;
              .el-input.el-input--suffix {
                height: 32px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .operation-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 50%;
          height: 32px;
          .bad {
            display: flex;
            align-items: center;
            height: 16px;
            width: auto;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 16px;
              width: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .contet {
            display: flex;
            align-items: center;
            height: 16px;
            width: auto;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 14px;
              width: 13px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .management {
            display: flex;
            align-items: center;
            height: 16px;
            width: auto;
            cursor: pointer;
            img {
              height: 14px;
              width: 14px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .info-detail {
        display: flex;
        align-items: center;
        height: 188px;
        width: 100%;
        background: #f4f5fa;
        border-radius: 4px;
        margin-top: 16px;
        .info-detail-left {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 32px;
          width: calc(100% - 190px);
          .platform {
            display: flex;
            flex-wrap: wrap;
            height: 20px;
            width: 100%;

            // .platform-type {
            //   height: 20px;
            //   width: 353px;
            //   line-height: 20px;
            //   font-size: 14px;
            //   font-family: PingFangSC-Regular, PingFang SC;
            //   font-weight: 400;
            //   color: #333333;
            // }

            .company {
              height: 20px;
              // width: calc(100% - 353px);
              width: 33%;
              line-height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-bottom: 32px;
            }
          }
        }
        .img {
          width: 110px;
          height: 110px;
          margin: 0 40px;
        }
      }
    }
    .info-type {
      width: 100%;
      height: auto;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 16px;
      .cut {
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 40px;
        border-bottom: 1px solid #cccccc;
        .cut-left {
          display: flex;
          width: 50%;
          height: 100%;
          align-items: center;
          .edit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 36px;
            background: #f58030;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
          }
          .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 100%;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 64px;
            cursor: pointer;
          }
        }
        .cut-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 50%;
          height: 100%;
          .edit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 36px;
            background: #f58030;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .account-information {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 60px 80px;
        // .list-box {
        //   display: flex;
        //   height: 20px;
        //   width: 100%;
        //   margin-bottom: 24px;
        .website-name {
          width: 45%;
          display: flex;
          align-items: center;
          height: auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 24px;
        }
        // }
      }
    }
    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 24px 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        span:nth-child(1) {
          z-index: 10;
        }
        span:nth-child(2) {
          font-size: 14px;
        }
        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }
      .data-box {
        display: flex;
        width: 100%;
        height: 137px;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;
        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }
        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: calc(100% - 104px);
          height: 100%;
          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 25px;
            margin-bottom: 16px;
            .name {
              height: 25px;
              width: 80%;
              line-height: 25px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .status {
              color: #02bc7c;
              margin-right: 46px;
            }
            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .subject-type {
              margin-right: 46px;
            }
            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}
.popover {
  // width: 300px;
  // height: 300px;
  // background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content:space-evenly
  .popover-type {
    cursor: pointer;
    width: 114px;
    min-height: 28px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 4px 10px;
    background: #ff4b1f;
    border-radius: 1px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.type {
  cursor: pointer;
  width: 114px;
  min-height: 28px;
  text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 4px 10px;
  background: #ff4b1f;
  border-radius: 1px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style>
/* //最外层div,修改背景色，边框 */
.el-popover.monitor-yt-popover {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 16px 0;
  /* border-color: #146ebd; */
}
/* //修改title颜色 */
.monitor-yt-popover .el-popover__title {
  color: white;
}
/* //修改下面的小三角，属性名根据组件的placement位置做相应修改 */
.monitor-yt-popover .popper__arrow::after {
  border-right-color: rgba(0, 0, 0, 0.6) !important;
}
</style>